import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import {Container, Box, Heading, Flex, Section, Text, Button} from "../../components/ui"
import * as sections from "../../components/sections";
import Fallback from "../../components/fallback";
import SeriesItem from "../../components/series-item";
import * as styles from "../../components/series-item.css";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

export default function ProductSeriesPage(props) {
  const { productSeriesPage } = props.data
  const rows = productSeriesPage.blocks.reduce(function (rows, key, index) {
    return (index % 2 == 0 ? rows.push([key])
        : rows[rows.length-1].push(key)) && rows;
  }, []);

  return (
    <Layout {...productSeriesPage}>
      <Box paddingY={5}>
        <Container width='narrow' style={{padding: 0}}>
          <Heading as="h1">{productSeriesPage.title}</Heading>
            {rows.map((row,index) => {
                const { id, blocktype, ...componentProps } = row[0]
                const Component = sections[blocktype] || Fallback
                if(row[1]) {
                  const Component1 = sections[row[1].blocktype] || Fallback
                  return (
                      <Flex gap={3} variant="responsive">
                        <Component key={id} {...componentProps} />
                        <Component1 key={row[1].id} image={row[1].image} link={row[1].link} text={row[1].text} title={row[1].title} blocktype={row[1].blocktype} />
                      </Flex>)
                } else{
                    return (
                        <Flex gap={3} variant="responsive">
                          <Component key={id} {...componentProps} />
                          <Container width="normal" className={styles.hiddenOddBox}>
                            <Box background="white" radius="large" className={styles.seriesContainer}>
                              <Box center paddingY={5} padding={10}>
                                <Section>
                                  <Container>
                                    <Box width="large">
                                      {row[0].image && (
                                          <GatsbyImage
                                              alt={row[0].image.alt}
                                              image={getImage(row[0].image.gatsbyImageData)}
                                              className={styles.aboutHeroImage}
                                          />
                                      )}
                                    </Box>
                                  </Container>
                                </Section>
                              </Box>
                            </Box>
                          </Container>
                        </Flex>
                    )
                  }
            })}
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query ProductSeriesPageContent($title: String!) {
    productSeriesPage(title: { eq: $title }) {
      id
      title
      blocks: content {
        id
        blocktype
        ...SeriesItemContent
      }
    }
  }
`
